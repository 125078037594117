/* eslint-disable react/prop-types */
import styles from './styles.module.css'
// import { useNavigate } from 'react-router-dom'
import {
  Clips,
  Folder,
  NavigateArrowRigth,
  NumberOne,
  NumberTwo,
  Picture,
  Stars,
  SupermarketCart,
  Trash,
} from '../../../assets/svgs/icons'
import { useState, useEffect } from 'react'
import ModalComponent from '../../../components/ModalComponent'
import ModalArtiificialInteligency from './ModalArtificalInteligency'
import ModalUploads from './ModalUploads'
import Proptypes from 'prop-types'
import BackgroundRemoverModal from '../../../components/BackgroundRemoverModal'
import { PythonService } from '../../../services'
import ModalProducts from './ModalProducts'
import { SelectSquare } from '../../../components/Select/SelectSquare/SelectSquare'
import {
  notifyErrorCustom,
  notifySuccessCustom,
  notifyWarningCustom,
} from '../../../utils/notify'
import { Loading } from '../../../components/Loading'
import { ProductFruits } from 'react-product-fruits'
import { useTranslation } from 'react-i18next'

const STEPS = {
  STEP_FIRST_PASS_HEADLINES: 'stepHeadlines',
  STEP_IMAGE_BANK: 'stepImageBank',
  STEP_THIRD_PASS_PUBLICATION: 'stepPublication',
}

function StepSecondPass({
  setStep,
  imageArray,
  setImageArray,
  imageGaleryArray,
  setImageGaleryArray,
  imageAIArray,
  setImageAIArray,
  imageUploadArray,
  setImageUploadArray,
  imageProductsArray,
  setImageProductsArray,
  setGenerateArtIa,
  generateArtIa,
}) {
  const { t } = useTranslation()
  const [isModalOpen, setModalOpen] = useState(false)
  const [isModalAIOpen, setModalAIOpen] = useState(false)
  const [isModalUploadsOpen, setModalUploadsOpen] = useState(false)
  const [isModalProductsOpen, setModalProductsOpen] = useState(false)
  const [imageCategoriesOptions, setImageCategoriesOptions] = useState([
    { label: '', value: '' },
  ])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    loadDropdownsImageCategory()
  }, [])

  const handleGenerateArteAi = () => {
    if (!generateArtIa) {
      setGenerateArtIa(true)
      setImageUploadArray([])
      setImageArray([])
      setImageGaleryArray([])
      setImageAIArray([])
      setImageProductsArray([])
      // setImageArray([])
    } else {
      setGenerateArtIa(false)
    }
    // generateArtIa ? setGenerateArtIa(false) : setGenerateArtIa(true)
  }

  const loadDropdownsImageCategory = async () => {
    // faça chamada dos serviços
    // const { data: categories, responseStatus: categoriesStatus } =
    //   await getAllImageCategories()

    //altere estados
    // if (categoriesStatus === 200)
    //   setImageCategoriesOptions(
    //     categories.map((e) => ({ label: e.name, value: e.id }))
    //   )
    const dataCategories = [
      { label: 'Principal', value: 'Principal' },
      { label: 'Complementar', value: 'Complementar' },
      { label: 'Background', value: 'Background' },
    ]

    setImageCategoriesOptions(dataCategories)
  }

  const handleAddCategoryGalery = (indice, idCategory) => {
    let image = imageGaleryArray[indice]

    image.categoryId = idCategory
  }

  const handleAddCategoryImageBank = (indice, idCategory) => {
    let image = imageArray[indice]

    image.categoryId = idCategory
  }

  const handleAddCategoryAi = (indice, idCategory) => {
    let image = imageAIArray[indice]

    image.categoryId = idCategory
  }

  const handleAddCategoryUpload = (indice, idCategory) => {
    let image = imageUploadArray[indice]

    image.categoryId = idCategory
  }

  const handleAddCategoryProduct = (indice, idCategory) => {
    let image = imageProductsArray[indice]

    image.categoryId = idCategory
  }

  const handleOpenModal = (modal) => {
    if (
      generateArtIa &&
      (imageUploadArray.length > 0 ||
        imageArray.length > 0 ||
        imageGaleryArray.length > 0 ||
        imageAIArray.length > 0 ||
        imageProductsArray.length > 0)
    ) {
      notifyErrorCustom(t('stepSecondPass.error.oneImage'))
      return
    }
    if (modal === 'galeria') {
      setModalOpen(true)
    }
    if (modal === 'inteligenciaArtificial') {
      setModalAIOpen(true)
    }
    if (modal === 'uploads') {
      setModalUploadsOpen(true)
    }
    if (modal === 'produtos') {
      setModalProductsOpen(true)
    }
  }

  const handleCloseModal = (modal) => {
    if (modal === 'galeria') {
      setModalOpen(false)
    }
    if (modal === 'inteligenciaArtificial') {
      setModalAIOpen(false)
    }
    if (modal === 'uploads') {
      setModalUploadsOpen(false)
    }
    if (modal === 'produtos') {
      setModalProductsOpen(false)
    }
  }

  const nextButton = async (navigation) => {
    if (
      generateArtIa &&
      navigation === 'imageBank' &&
      (imageUploadArray.length > 0 ||
        imageArray.length > 0 ||
        imageGaleryArray.length > 0 ||
        imageAIArray.length > 0 ||
        imageProductsArray.length > 0)
    ) {
      notifyErrorCustom(t('stepSecondPass.error.oneImage'))
      return
    }
    if (navigation === 'back') {
      setStep(STEPS.STEP_FIRST_PASS_HEADLINES)
    }
    if (navigation === 'next') {
      setStep(STEPS.STEP_THIRD_PASS_PUBLICATION)
    }
    if (navigation === 'imageBank') {
      setStep(STEPS.STEP_IMAGE_BANK)
    }
  }

  const handleRemoveFile = (index) => {
    var indice = imageGaleryArray.indexOf(index)
    imageGaleryArray.splice(indice, 1)

    setImageGaleryArray((current) => [...current])
  }

  const handleRemoveImages = (index) => {
    var indice = imageArray.indexOf(index)
    imageArray.splice(indice, 1)

    setImageArray((current) => [...current])
  }

  const handleRemoveUploadImage = (index) => {
    var indice = imageUploadArray.indexOf(index)
    imageUploadArray.splice(indice, 1)

    setImageUploadArray((current) => [...current])
  }

  const handleRemoveProductImage = (index) => {
    const indice = imageProductsArray.indexOf(index)
    imageProductsArray.splice(indice, 1)

    setImageProductsArray((current) => [...current])
  }

  const handleRemoveAIImage = (index) => {
    var indice = imageAIArray.indexOf(index)
    imageAIArray.splice(indice, 1)

    setImageAIArray((current) => [...current])
  }

  const handleRemoveBackground = async (image, typeImage, indice) => {
    setLoading(true)
    let noBgImage = []

    if (typeImage === 'imageProduct') {
      const { data } = await PythonService.postRemoveBackground({
        image: image[indice].fileDataBase64,
      })
      noBgImage = data.data.images
    } else if (typeImage === 'imageArray') {
      const { data } = await PythonService.postRemoveBackground({
        //  image: image[indice].image
        image: 'data:image/jpeg;base64,' + image[indice].base64,
      })

      noBgImage = data.data.images
    } else {
      const { data } = await PythonService.postRemoveBackground({
        image: image[indice].image,
      })

      noBgImage = data.data.images
    }

    switch (typeImage) {
      case 'imageAi':
        imageAIArray[0].image = noBgImage[1]?.image
        imageAIArray[0].isOriginal = noBgImage[1]?.isOriginal
        setImageAIArray([...imageAIArray])
        break

      case 'imageUpload':
        imageUploadArray[0].image = noBgImage[1]?.image
        imageUploadArray[0].isOriginal = noBgImage[1]?.isOriginal
        setImageUploadArray([...imageUploadArray])
        break
      case 'imageProduct':
        imageProductsArray[0].fileDataBase64 = noBgImage[1]?.image
        imageProductsArray[0].isOriginal = noBgImage[1]?.isOriginal
        setImageProductsArray([...imageProductsArray])
        break
      case 'imageArray':
        imageArray[indice].image = noBgImage[1]?.image
        imageArray[indice].isOriginal = noBgImage[1]?.isOriginal
        setImageArray([...imageArray])
        break
      default:
        break
    }
    setLoading(false)
  }

  const HeaderStepRender = () => {
    return (
      <>
        <div className={styles.title_new_posts}>
          <p className={styles.title_new_posts_label}>
            {t('newPublication.title.new')}
          </p>
          <p className={styles.title_new_posts_title}>
            {t('newPublication.title.publication')}
          </p>
        </div>
        <div className={styles.title_steps}>
          <div className={styles.first_pass}>
            <div>
              <NumberOne width={'40px'} height={'40px'} />
            </div>
            <div className={styles.first_pass_title}>
              <p className={styles.first_pass_title_text}>
                {t('newPublication.step.first')}
              </p>
              <p className={styles.first_pass_subtitle}>
                {t('newPublication.step.settings')}
              </p>
            </div>
          </div>

          <div className={styles.second_pass}>
            <div className={styles.second_pass_box_number}>
              <NavigateArrowRigth color={'#EEEEEE'} />
              <NumberTwo width={'40px'} height={'40px'} />
            </div>
            <div>
              <p className={styles.first_pass_title_text}>
                {t('newPublication.step.second')}
              </p>
              <p className={styles.first_pass_subtitle}>
                {t('newPublication.step.image')}
              </p>
            </div>
          </div>

          <div className={styles.next_pass}>
            <NavigateArrowRigth />
          </div>
        </div>
      </>
    )
  }

  const handleNextStep = () => {
    let checkMainCategory = false

    if (
      generateArtIa &&
      imageUploadArray.length <= 0 &&
      imageArray.length <= 0 &&
      imageGaleryArray.length <= 0 &&
      imageAIArray.length <= 0 &&
      imageProductsArray.length <= 0
    ) {
      notifyErrorCustom(t('stepSecondPass.error.oneImage_please'))
      return
    }

    for (const image of imageArray) {
      if (generateArtIa) {
        image.categoryId = 'Background'
      } else {
        if (!image.categoryId) {
          notifyErrorCustom(t('stepSecondPass.error.category'))
          return
        } else if (image.categoryId == 'Principal') {
          checkMainCategory = true
        }
      }
    }

    for (const image of imageAIArray) {
      if (generateArtIa) {
        image.categoryId = 'Background'
      } else {
        if (!image.categoryId) {
          notifyErrorCustom(t('stepSecondPass.error.category'))
          return
        } else if (image.categoryId == 'Principal') {
          checkMainCategory = true
        }
      }
    }

    for (const image of imageGaleryArray) {
      if (generateArtIa) {
        image.categoryId = 'Background'
      } else {
        if (!image.categoryId) {
          notifyErrorCustom(t('stepSecondPass.error.category'))
          return
        } else if (image.categoryId == 'Principal') {
          checkMainCategory = true
        }
      }
    }

    for (const image of imageUploadArray) {
      if (generateArtIa) {
        image.categoryId = 'Background'
      } else {
        if (!image.categoryId) {
          notifyErrorCustom(t('stepSecondPass.error.category'))
          return
        } else if (image.categoryId == 'Principal') {
          checkMainCategory = true
        }
      }
    }

    for (const image of imageProductsArray) {
      if (generateArtIa) {
        image.categoryId = 'Background'
      } else {
        if (!image.categoryId) {
          notifyErrorCustom(t('stepSecondPass.error.category'))
          return
        } else if (image.categoryId == 'Principal') {
          checkMainCategory = true
        }
      }
    }

    if (!checkMainCategory && !generateArtIa) {
      notifyErrorCustom(t('stepSecondPass.error.mainImage'))
      return
    }

    setStep('stepArts')
  }
  const storage = JSON.parse(window.localStorage.getItem('userData')).userEmail
  const userInfo = {
    username: storage, // REQUIRED, must be unique
  }

  const ImageField = () => {
    return (
      <div
        style={{
          // backgroundColor: 'red',
          display: 'flex',
          flexDirection: 'column',

          alignItems: 'center',

          padding: '20px',
          paddingBottom: '0px',

          marginRight: '22px',
          height: 'calc(100vh - 246px)',

          overflowY: 'auto',
        }}
      >
        <div className={styles.container_imagem}>
          <Loading enable={loading} />
          <ProductFruits
            workspaceCode="FzMKHdjfiHGoWeMW"
            language="en"
            user={userInfo}
          />
          <div
            className={
              imageArray.length +
                imageAIArray.length +
                imageGaleryArray.length +
                imageUploadArray.length !==
              0
                ? styles.box_imagem_short
                : styles.box_imagem
            }
          >
            <div className={styles.container_content_imagem}>
              <p className={styles.container_content_imagem_title}>
                {t('stepSecondPass.title.chooseImages')}{' '}
                <span className={styles.content_imagem_title_bold}>
                  {t('stepSecondPass.title.publication')}{' '}
                </span>
                {t('stepSecondPass.title.or')}{' '}
                <span className={styles.content_imagem_title_bold}>
                  {t('stepSecondPass.title.carousel')}
                </span>
              </p>
            </div>

            <div className={styles.container_box_image}>
              <ModalComponent
                isOpen={isModalOpen}
                onClose={() => handleCloseModal('galeria')}
                className={styles.teste}
              >
                <BackgroundRemoverModal
                  onCloseModal={setImageGaleryArray}
                  closeModal={handleCloseModal}
                  imageArray={imageGaleryArray}
                />
              </ModalComponent>

              <ModalComponent
                isOpen={isModalAIOpen}
                onClose={() => handleCloseModal('inteligenciaArtificial')}
                className={styles.teste}
              >
                <ModalArtiificialInteligency
                  setImageAIArray={setImageAIArray}
                  closeModal={handleCloseModal}
                />
              </ModalComponent>

              <ModalComponent
                isOpen={isModalUploadsOpen}
                onClose={() => handleCloseModal('uploads')}
                className={styles.teste}
              >
                <ModalUploads
                  closeModal={handleCloseModal}
                  setImageUploadArray={setImageUploadArray}
                />
              </ModalComponent>

              <ModalComponent
                isOpen={isModalProductsOpen}
                onClose={() => handleCloseModal('produtos')}
                className={styles.teste}
              >
                <ModalProducts
                  closeModal={handleCloseModal}
                  setImageProductsArray={setImageProductsArray}
                />
              </ModalComponent>
              <div
                className={styles.box_image}
                onClick={() => handleOpenModal('galeria')}
              >
                <div className={styles.box_image_svg}>
                  <Clips width={30} height={30} />
                </div>
                <div className={styles.box_image_text}>
                  <p className={styles.box_image_title}>
                    {t('stepSecondPass.button.gallery')}
                  </p>
                </div>
              </div>

              <div
                className={styles.box_image}
                onClick={() => {
                  nextButton('imageBank')
                }}
              >
                <div className={styles.box_image_svg}>
                  <Picture width={30} height={30} />
                </div>
                <div className={styles.box_image_text}>
                  <p className={styles.box_image_title}>
                    {t('stepSecondPass.button.imageBank')}
                  </p>
                </div>
              </div>

              <div
                className={styles.box_image}
                onClick={() => handleOpenModal('inteligenciaArtificial')}
              >
                <div className={styles.box_image_svg}>
                  <Stars width={30} height={30} />
                </div>
                <div className={styles.box_image_text}>
                  <p className={styles.box_image_title}>
                    {t('stepSecondPass.button.artificialIntelligence')}
                  </p>
                </div>
              </div>

              <div
                className={styles.box_image}
                onClick={() => handleOpenModal('uploads')}
              >
                <div className={styles.box_image_svg}>
                  <Folder width={30} height={30} />
                </div>
                <div className={styles.box_image_text}>
                  <p className={styles.box_image_title}>
                    {t('stepSecondPass.button.myUploads')}
                  </p>
                </div>
              </div>

              <div
                className={styles.box_image}
                onClick={() => handleOpenModal('produtos')}
              >
                <div className={styles.box_image_svg}>
                  <SupermarketCart width={30} height={30} />
                </div>
                <div className={styles.box_image_text}>
                  <p className={styles.box_image_title}>
                    {t('stepSecondPass.button.products')}
                  </p>
                </div>
              </div>
            </div>

            <div className={styles.container_itens_selected}>
              {/* <div
                style={{
                  marginTop: 18,
                  backgroundColor: generateArtIa ? '#f5eaf9' : '#FBFBFB', //'#6A0098',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                }}
                className={styles.selectedModalCard}
                onClick={() => handleGenerateArteAi()}
              >
                <div
                  style={{
                    width: 34,
                    height: 34,
                    border: '1px solid #dcdcdc',
                    borderRadius: 200,
                    backgroundColor: generateArtIa ? '#6A0098' : '#FBFBFB', //'#6A0098',
                  }}
                ></div>

                <div>
                  <div className={styles.selectedModalTextTitle}>
                    {t('stepSecondPass.button.generateImagesTitle')}
                  </div>

                  <div className={styles.selectedModalTextSubtitle}>
                    {t('stepSecondPass.button.generateImagesSubtitle')}
                  </div>
                </div>
                <div className={styles.betaIndicator}>BETA</div>
              </div> */}
              {imageArray.length !== 0 &&
                imageArray.map((image, indice) => (
                  <div key={image.id} className={styles.item_selected}>
                    <div className={styles.box_item}>
                      <div className={styles.box_item_image}>
                        <img
                          className={styles.image}
                          src={
                            image.base64
                              ? 'data:image/png;base64,' + image.base64
                              : image.url
                          }
                          // src={'data:image/png;base64,' + image.base64}
                          alt=""
                        />
                      </div>
                      <div className={styles.box_item_title}>
                        <p className={styles.item_description_title}>
                          {image.title}
                        </p>
                        <p className={styles.item_description_size}>
                          {t('stepSecondPass.title.size')}: <b>{image.size}</b>
                        </p>
                      </div>
                    </div>
                    <div className={styles.box_actions}>
                      <div className={styles.button_galery}>
                        <Picture color={'#660098'} width={12} height={12} />
                        <p className={styles.button_galery_title}>
                          {t('stepSecondPass.button.imageBank')}
                        </p>
                      </div>
                      <button
                        className={styles.button_delete}
                        onClick={() => handleRemoveImages(image.id)}
                      >
                        {t('stepSecondPass.title.delete')}
                        <Trash width={'15px'} height={'15px'} />
                      </button>
                      <button
                        className={styles.button_remove_background}
                        onClick={() =>
                          handleRemoveBackground(
                            imageArray,
                            'imageArray',
                            indice
                          )
                        }
                      >
                        {t('stepSecondPass.button.removeBackground')}
                        <Stars color={'#fff'} width={'15px'} height={'15px'} />
                      </button>
                      <div
                        style={{
                          pointerEvents: generateArtIa ? 'none' : 'auto',
                        }}
                      >
                        <SelectSquare
                          backgroundColor={
                            generateArtIa ? '#dcdcdc' : '#6a0098'
                          }
                          arrowColor={generateArtIa ? 'black' : '#fff'}
                          border={true}
                          borderColor={'#dadada'}
                          placeholder={t('stepSecondPass.title.imageCategory')}
                          arrowDirection="down"
                          options={imageCategoriesOptions}
                          fontSize={12}
                          padding="7.2px 14.4px"
                          radius="4px"
                          minWidth="165px"
                          color={generateArtIa ? 'gray' : '#fff'}
                          defaultSelected={
                            generateArtIa
                              ? imageCategoriesOptions.find(
                                  (e) => e.value === 'Background'
                                )
                              : null
                          }
                          onClickItem={({ value }) =>
                            handleAddCategoryImageBank(indice, value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}

              {imageGaleryArray.length !== 0 &&
                imageGaleryArray.map((imageGalery, indice) => (
                  <div key={imageGalery.id} className={styles.item_selected}>
                    <div className={styles.box_item}>
                      <div className={styles.box_item_image}>
                        <img
                          className={styles.image}
                          src={imageGalery.fileDataBase64}
                          alt=""
                        />
                      </div>
                      <div className={styles.box_item_title}>
                        <p className={styles.item_description_title}>
                          {imageGalery.name}
                        </p>
                        <p className={styles.item_description_size}>
                          {t('stepSecondPass.title.size')}:{' '}
                          <b>{imageGalery.size}</b>
                        </p>
                      </div>
                    </div>
                    <div className={styles.box_actions}>
                      <div className={styles.button_galery}>
                        <Clips color={'#660098'} width={12} height={12} />
                        <p className={styles.button_galery_title}>
                          {t('stepSecondPass.button.gallery')}
                        </p>
                      </div>
                      <button
                        className={styles.button_delete}
                        onClick={() => handleRemoveFile(imageGalery.id)}
                      >
                        {t('stepSecondPass.title.delete')}{' '}
                        <Trash width={'15px'} height={'15px'} />
                      </button>
                      <button
                        disabled={imageGalery.isBackgroundRemoved}
                        style={{
                          opacity: !imageGalery.isBackgroundRemoved ? 1 : 0.5,
                          cursor: !imageGalery.isBackgroundRemoved
                            ? 'pointer'
                            : 'default',
                        }}
                        onClick={() => handleOpenModal('galeria')}
                        className={styles.button_remove_background}
                      >
                        {t('stepSecondPass.button.removeBackground')}{' '}
                        <Stars width={'15px'} height={'15px'} />
                      </button>
                      <div
                        style={{
                          pointerEvents: generateArtIa ? 'none' : 'auto',
                        }}
                      >
                        <SelectSquare
                          backgroundColor={
                            generateArtIa ? '#dcdcdc' : '#6a0098'
                          }
                          arrowColor={generateArtIa ? 'black' : '#fff'}
                          border={true}
                          borderColor={'#dadada'}
                          placeholder={t('stepSecondPass.title.imageCategory')}
                          arrowDirection="down"
                          options={imageCategoriesOptions}
                          fontSize={12}
                          padding="7.2px 14.4px"
                          radius="4px"
                          minWidth="165px"
                          color={generateArtIa ? 'gray' : '#fff'}
                          defaultSelected={
                            generateArtIa
                              ? imageCategoriesOptions.find(
                                  (e) => e.value === 'Background'
                                )
                              : null
                          }
                          onClickItem={({ value }) =>
                            handleAddCategoryGalery(indice, value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}

              {imageAIArray.length !== 0 &&
                imageAIArray.map((image, indice) => {
                  return (
                    <div key={image.id} className={styles.item_selected}>
                      <div className={styles.box_item}>
                        <div className={styles.box_item_image}>
                          <img
                            className={styles.image}
                            src={image?.image}
                            // src={image.imageBase64}
                            alt=""
                          />
                        </div>

                        <div className={styles.box_item_title}>
                          <p className={styles.item_description_title}></p>
                          <p className={styles.item_description_size}></p>
                        </div>
                      </div>
                      <div className={styles.box_actions}>
                        <div className={styles.button_galery}>
                          <Stars
                            color={'#660098'}
                            width={'12px'}
                            height={'12px'}
                          />
                          <p className={styles.button_galery_title}>
                            {t('stepSecondPass.button.artificialIntelligence')}
                          </p>
                        </div>
                        <button
                          className={styles.button_delete}
                          onClick={() => handleRemoveAIImage(imageAIArray.id)}
                        >
                          {t('stepSecondPass.title.delete')}{' '}
                          <Trash width={'15px'} height={'15px'} />
                        </button>

                        <button
                          className={styles.button_remove_background}
                          onClick={() =>
                            handleRemoveBackground(
                              imageAIArray,
                              'imageAi',
                              indice
                            )
                          }
                        >
                          {t('stepSecondPass.button.removeBackground')}
                          <Stars
                            color={'#fff'}
                            width={'15px'}
                            height={'15px'}
                          />
                        </button>
                        <div
                          style={{
                            pointerEvents: generateArtIa ? 'none' : 'auto',
                          }}
                        >
                          <SelectSquare
                            backgroundColor={
                              generateArtIa ? '#dcdcdc' : '#6a0098'
                            }
                            arrowColor={generateArtIa ? 'black' : '#fff'}
                            border={true}
                            borderColor={'#dadada'}
                            placeholder={t(
                              'stepSecondPass.title.imageCategory'
                            )}
                            arrowDirection="down"
                            options={imageCategoriesOptions}
                            fontSize={12}
                            padding="7.2px 14.4px"
                            radius="4px"
                            minWidth="165px"
                            color={generateArtIa ? 'gray' : '#fff'}
                            defaultSelected={
                              generateArtIa
                                ? imageCategoriesOptions.find(
                                    (e) => e.value === 'Background'
                                  )
                                : null
                            }
                            onClickItem={({ value }) =>
                              handleAddCategoryAi(indice, value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )
                })}

              {imageUploadArray.length !== 0 &&
                imageUploadArray.map((image, indice) => (
                  <div key={image.id} className={styles.item_selected}>
                    <div className={styles.box_item}>
                      <div className={styles.box_item_image}>
                        <img
                          className={styles.image}
                          src={image.image}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className={styles.box_actions}>
                      <div className={styles.button_galery}>
                        <Folder
                          color={'#660098'}
                          width={'12px'}
                          height={'12px'}
                        />
                        <p className={styles.button_galery_title}>
                          {t('stepSecondPass.button.myUploads')}
                        </p>
                      </div>
                      <button
                        className={styles.button_delete}
                        onClick={() => handleRemoveUploadImage(image.id)}
                      >
                        {t('stepSecondPass.title.delete')}{' '}
                        <Trash width={'15px'} height={'15px'} />
                      </button>

                      <button
                        className={styles.button_remove_background}
                        onClick={() =>
                          handleRemoveBackground(
                            imageUploadArray,
                            'imageUpload',
                            indice
                          )
                        }
                      >
                        {t('stepSecondPass.button.removeBackground')}
                        <Stars color={'#fff'} width={'15px'} height={'15px'} />
                      </button>
                      <div
                        style={{
                          pointerEvents: generateArtIa ? 'none' : 'auto',
                        }}
                      >
                        <SelectSquare
                          backgroundColor={
                            generateArtIa ? '#dcdcdc' : '#6a0098'
                          }
                          arrowColor={generateArtIa ? 'black' : '#fff'}
                          border={true}
                          borderColor={'#dadada'}
                          placeholder={t('stepSecondPass.title.imageCategory')}
                          arrowDirection="down"
                          options={imageCategoriesOptions}
                          fontSize={12}
                          padding="7.2px 14.4px"
                          radius="4px"
                          minWidth="165px"
                          color={generateArtIa ? 'gray' : '#fff'}
                          defaultSelected={
                            generateArtIa
                              ? imageCategoriesOptions.find(
                                  (e) => e.value === 'Background'
                                )
                              : null
                          }
                          onClickItem={({ value }) =>
                            handleAddCategoryUpload(indice, value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}

              {imageProductsArray.length !== 0 &&
                imageProductsArray.map((image, indice) => (
                  <div key={image.id} className={styles.item_selected}>
                    <div className={styles.box_item}>
                      <div className={styles.box_item_image}>
                        <img
                          className={styles.image}
                          src={image.fileDataBase64}
                          alt=""
                        />
                      </div>
                      <div className={styles.box_item_title}>
                        <p className={styles.item_description_title}>
                          {image.name}
                        </p>
                        <p className={styles.item_description_size}>
                          {t('stepSecondPass.title.size')}: <b>{image.size}</b>
                        </p>
                      </div>
                    </div>
                    <div className={styles.box_actions}>
                      <div className={styles.button_galery}>
                        <SupermarketCart
                          color={'#660098'}
                          width={'12px'}
                          height={'12px'}
                        />
                        <p className={styles.button_galery_title}>
                          {t('stepSecondPass.button.products')}
                        </p>
                      </div>
                      <button
                        className={styles.button_delete}
                        onClick={() => handleRemoveProductImage(image.id)}
                      >
                        {t('stepSecondPass.title.delete')}{' '}
                        <Trash width={'15px'} height={'15px'} />
                      </button>

                      <button
                        className={styles.button_remove_background}
                        onClick={() =>
                          handleRemoveBackground(
                            imageProductsArray,
                            'imageProduct',
                            indice
                          )
                        }
                      >
                        {t('stepSecondPass.button.removeBackground')}
                        <Stars color={'#fff'} width={'15px'} height={'15px'} />
                      </button>
                      <div
                        style={{
                          pointerEvents: generateArtIa ? 'none' : 'auto',
                        }}
                      >
                        <SelectSquare
                          backgroundColor={
                            generateArtIa ? '#dcdcdc' : '#6a0098'
                          }
                          arrowColor={generateArtIa ? 'black' : '#fff'}
                          border={true}
                          borderColor={'#dadada'}
                          placeholder={t('stepSecondPass.title.imageCategory')}
                          arrowDirection="down"
                          options={imageCategoriesOptions}
                          fontSize={12}
                          padding="7.2px 14.4px"
                          radius="4px"
                          minWidth="165px"
                          color={generateArtIa ? 'gray' : '#fff'}
                          defaultSelected={
                            generateArtIa
                              ? imageCategoriesOptions.find(
                                  (e) => e.value === 'Background'
                                )
                              : null
                          }
                          onClickItem={({ value }) =>
                            handleAddCategoryProduct(indice, value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const FooterStepRender = () => {
    return (
      <div
        style={{
          marginRight: '22px',
        }}
      >
        <div className={styles.button_nav}>
          <button
            className={styles.button_back}
            onClick={() => {
              setStep('stepFirstPass')
            }}
          >
            <p className={styles.button_title}>
              {t('stepSecondPass.button.back')}
            </p>
          </button>
          <button
            className={styles.button_next}
            onClick={() => {
              handleNextStep()
            }}
          >
            {t('stepSecondPass.button.proceed')}
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container_header}>
      {HeaderStepRender()}
      {ImageField()}
      {FooterStepRender()}
    </div>
  )
}

export default StepSecondPass

StepSecondPass.propTypes = {
  setStep: Proptypes.func,
  imageArray: Proptypes.array,
  setImageArray: Proptypes.array,
  imageGaleryArray: Proptypes.array,
  setImageGaleryArray: Proptypes.array,
  imageAIArray: Proptypes.array,
  setImageAIArray: Proptypes.func,
  imageUploadArray: Proptypes.array,
  setImageUploadArray: Proptypes.func,
  setImageProductsArray: Proptypes.func,
}
