/* eslint-disable react/prop-types */
import styles from './styles.module.css'
import SchedulerCalendar from '../../../components/SchedulerCalendar'
import CarouselHours from '../../../components/CarouselHours'
import { MultiplySelectSquare } from '../../../components/Select/MultiplySelectSquare'

import {
  notifyErrorCustom,
  notifySuccessCustom,
  notifyWarningCustom,
} from '../../../utils/notify'

import {
  Picture,
  Instagram,
  Linkedin,
  Pinterest,
  TikTok,
  XTwitter,
  Calendar2,
  Subtract,
  PinMapsIcon,
  MorePersonsIcon,
  LinksIcon,
  HashtagIcon,
  FacebookChatIcon,
  Cross,
  LockClosed,
  TikTokIcon,
} from '../../../assets/svgs/icons'

import { useState, useEffect } from 'react'

import { ModalComponent } from '../../../components/ModalComponent'
import { date } from 'yup'
import { ImageRender } from '../../../components/ImageRender'
import { SelectSquare } from '../../../components/Select/SelectSquare/SelectSquare'
import { TiktokService } from '../../../services'
import { useClient } from '../../../hooks/useClient'
import { useTranslation } from 'react-i18next'

function StepFinish({
  setStep,
  imagesSelectedsArrayObj,
  setImagesSelectedsArrayObj,
  imagesSelectedId,
  legendList,
  setPublicationTypePost,
  // setTiktokMusic,
  // tiktokMusic,
  // setTiktokPrivacyLevel,
  // tiktokPrivacyLevel,
  // setTiktokComment,
  // tiktokComment,
  // selectedObjectItem,
}) {
  const { t } = useTranslation()
  const [oppenedModalLocale, setOppenedModalLocale] = useState(false)
  const [oppenedModalPersons, setOppenedModalPersons] = useState(false)
  const { clientData } = useClient()

  const [oppennedModalLinks, setOppennedModalLinks] = useState(false)
  const [oppennedModalHashtags, setOppennedModalHashtags] = useState(false)

  const [selectedDate, setSelectedDate] = useState(new Date().toDateString())
  const [valueHour, setValueHour] = useState('')
  const [valueMinute, setValueMinute] = useState('')
  const [tiktokCreatorInfo, setTiktokCreatorInfo] = useState(false)
  const [dropDownTiktok, setDropDownTiktok] = useState(false)
  const [tiktokPrivacyLevel, setTiktokPrivacyLevel] = useState(false)
  const [tiktokComment, setTiktokComment] = useState(false)
  const [tiktokMusic, setTiktokMusic] = useState(false)
  const [tiktokDuet, setTiktokDuet] = useState(false)
  const [tiktokStitch, setTiktokStitch] = useState(false)
  const [tiktokContentDisclosure, setTiktokContentDisclosure] = useState(false)
  const [tiktokYourBrand, setTiktokYourBrand] = useState(false)
  const [tiktokBrandedContent, setTiktokBrandedContent] = useState(false)
  // const [publicationTypePost, setPublicationTypePost] = useState(1)
  const [valueItem, setValueItem] = useState(1)

  const [tiktokBrandedInfo, setTiktokBrandedInfo] = useState(
    'Você precisa indicar se seu conteúdo promove você mesmo, um terceiro ou ambos.'
  )

  const [selectedValues, setSelectedValues] = useState({})

  const imageUrl = imagesSelectedsArrayObj.find(
    (item) => item.id === imagesSelectedId.current
  ).image

  const legendId = legendList[imagesSelectedId.current]

  const handleTiktokContentDisclosure = () => {
    if (tiktokPrivacyLevel === 'SELF_ONLY') {
      setTiktokContentDisclosure(false)
      setTiktokYourBrand(false)
      setTiktokBrandedContent(false)
      notifyErrorCustom(t('stepFinish.error.contentDisclosure'))
      return
    }
    if (tiktokContentDisclosure) {
      setTiktokContentDisclosure(false)
      setTiktokYourBrand(false)
      setTiktokBrandedContent(false)
      setTiktokBrandedInfo(
        'Você precisa indicar se seu conteúdo promove você mesmo, um terceiro ou ambos.'
      )
    } else {
      setTiktokContentDisclosure(true)
    }
  }

  const handleYourBrand = () => {
    if (!tiktokYourBrand && !tiktokBrandedContent) {
      setTiktokYourBrand(true)
      setTiktokBrandedInfo(
        <>
          Sua foto/vídeo será rotulado como "Conteúdo promocional"
          <br></br>Ao selecionar a opção "Música", você concorda com a{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
            rel="noreferrer"
          >
            Confirmação de Uso de Música do TikTok
          </a>
        </>
      )
    } else if (!tiktokYourBrand && tiktokBrandedContent) {
      setTiktokYourBrand(true)
      setTiktokBrandedInfo(
        <>
          Sua foto/vídeo será rotulado como "Parceria paga"
          <br></br>
          Ao postar, você concorda com a{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/bc-policy/en"
            rel="noreferrer"
          >
            Política de conteúdo de marca
          </a>{' '}
          e com a{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
            rel="noreferrer"
          >
            Confirmação de Uso de Música do TikTok
          </a>
        </>
      )
    } else if (tiktokYourBrand && tiktokBrandedContent) {
      setTiktokYourBrand(false)
      setTiktokBrandedInfo(
        <>
          Sua foto/vídeo será rotulado como "Parceria paga"
          <br></br>
          Ao postar, você concorda com a{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/bc-policy/en"
            rel="noreferrer"
          >
            Política de conteúdo de marca
          </a>{' '}
          e com a{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
            rel="noreferrer"
          >
            Confirmação de Uso de Música do TikTok
          </a>
        </>
      )
    } else {
      setTiktokYourBrand(false)
      setTiktokBrandedInfo(
        'Você precisa indicar se seu conteúdo promove você mesmo, um terceiro ou ambos.'
      )
    }
  }

  const handleYourBrandingContent = () => {
    if (!tiktokBrandedContent && !tiktokYourBrand) {
      setTiktokBrandedContent(true)
      setTiktokBrandedInfo(
        <>
          Sua foto/vídeo será rotulado como "Parceria paga"
          <br></br>
          Ao postar, você concorda com a{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/bc-policy/en"
            rel="noreferrer"
          >
            Política de conteúdo de marca
          </a>{' '}
          e com a{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
            rel="noreferrer"
          >
            Confirmação de Uso de Música do TikTok
          </a>
        </>
      )
    } else if (!tiktokBrandedContent && tiktokYourBrand) {
      setTiktokBrandedContent(true)
      setTiktokBrandedInfo(
        <>
          Sua foto/vídeo será rotulado como "Parceria paga"
          <br></br>
          Ao postar, você concorda com a{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/bc-policy/en"
            rel="noreferrer"
          >
            Política de conteúdo de marca
          </a>{' '}
          e com a{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
            rel="noreferrer"
          >
            Confirmação de Uso de Música do TikTok
          </a>
        </>
      )
    } else if (tiktokBrandedContent && tiktokYourBrand) {
      setTiktokBrandedContent(false)
      setTiktokBrandedInfo(
        <>
          Sua foto/vídeo será rotulado como "Conteúdo promocional"
          <br></br>Ao selecionar a opção "Música", você concorda com a{' '}
          <a
            target="_blank"
            href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
            rel="noreferrer"
          >
            Confirmação de Uso de Música do TikTok
          </a>
        </>
      )
    } else {
      setTiktokBrandedContent(false)
      setTiktokBrandedInfo(
        'Você precisa indicar se seu conteúdo promove você mesmo, um terceiro ou ambos.'
      )
    }
  }

  const handlePrivacyLevel = (value) => {
    // if (value === 'SELF_ONLY') {
    //   setTiktokContentDisclosure(false)
    //   setTiktokYourBrand(false)
    //   setTiktokBrandedContent(false)
    //   notifyWarningCustom(
    //     'Configorações de "Divulgação de Conteúdo" não pode ser habilitada para publicações privadas'
    //   )
    // }
    setTiktokPrivacyLevel(value)
  }

  const handleClick = async (value) => {
    if (value === 3 && !tiktokCreatorInfo) {
      const tiktokCreatorInfoData = await TiktokService.getTiktokCreatorInfo(
        clientData.businessId
      )

      if (tiktokCreatorInfoData.dataT.error.code !== 'ok') {
        notifyErrorCustom(t('stepFinish.error.tiktokCreatorInfo'))
        return
      }

      setTiktokCreatorInfo(tiktokCreatorInfoData.dataT.data)
      setDropDownTiktok(
        tiktokCreatorInfoData.dataT.data.privacy_level_options.map((item) => ({
          label: item,
          value: item,
        }))
      )
    }

    if (value == 3) {
      setValueItem(4)
    }

    if (value == 4) {
      setValueItem(2)
    }

    if (value <= 2) {
      setValueItem(1)
    }
    if (selectedValues[value]) {
      const newValues = { ...selectedValues }
      delete newValues[value]
      setSelectedValues(newValues)
    } else {
      setSelectedValues({ ...selectedValues, [value]: true })
    }
  }

  useEffect(() => {
    // console.log(selectedObjectItem)
  }, [])

  const [valueItim, setValueItim] = useState(1)

  // const handleSelectedIntem = (item) => {
  //   // campaignRef.current = item
  //   setValueItim(item.id)
  // }

  function formatDate2(data) {
    data = new Date(data)

    let dia = data.getDate()
    let mes = data.getMonth() + 1 // getMonth() retorna mês de 0-11
    let ano = data.getFullYear()

    dia = dia < 10 ? '0' + dia : dia
    mes = mes < 10 ? '0' + mes : mes

    return dia + '/' + mes + '/' + ano
  }

  const handleSelectedIntem = (k, v) => {
    setPublicationTypePost((prevState) => ({ ...prevState, [k]: v }))
    if (v.length > 0) {
      const lastItem = v[v.length - 1]
      setValueItem(lastItem.id)
    }
  }

  const handleSelectDate = () => {
    let newSelectValues = Object.keys(selectedValues).toString() || ''

    if (!valueHour || !valueMinute) {
      notifyErrorCustom('Selecione um horário!')

      return
    }

    if (!newSelectValues.length) {
      notifyErrorCustom('Selecione ao menos uma rede social!')

      return
    }

    if (selectedValues[3] && !tiktokPrivacyLevel) {
      notifyErrorCustom(
        'Selecione a privacidade da sua publicação para o TikTok'
      )

      return
    }

    if (tiktokContentDisclosure && !tiktokYourBrand && !tiktokBrandedContent) {
      notifyErrorCustom(
        'Ao habilitar "Divulgação de Conteúdo" do TikTok você precisa informar se seu conteudo divulga "Sua marca" ou "Conteúdo de marca"'
      )
      return
    }

    const newImagesSelectedsArrayObj = imagesSelectedsArrayObj.map((item) => {
      if (item.id === imagesSelectedId.current) {
        return {
          ...item,
          // date: data.formatDate,
          // hour: data.valueHour + ':' + data.valueMinute,
          // valueHour: data.valueHour,
          // valueMinute: data.valueMinute,
          // selectedDate: data.selectedDate,
          // sketch: data.sketch,
          // socialTypes: data.socialTypes,

          date: formatDate2(selectedDate),
          hour: valueHour + ':' + valueMinute,
          valueHour: valueHour,
          valueMinute: valueMinute,
          selectedDate: selectedDate,
          sketch: false,
          socialTypes: newSelectValues,

          schedule: true,

          tiktokMusic: tiktokMusic,
          tiktokPrivacyLevel: tiktokPrivacyLevel,
          tiktokComment: tiktokComment,
          brandContentToggle: tiktokBrandedContent,
          brandOrganicToggle: tiktokYourBrand,
        }
      }
      return item
    })

    setImagesSelectedsArrayObj(newImagesSelectedsArrayObj)

    setStep('stepSchedule')
  }

  const ScheduleInfoField = () => {
    const hours = [
      '01:00',
      '02:00',
      '03:00',
      '04:00',
      '05:00',
      '06:00',
      '07:00',
      '08:00',
      '09:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00 ',
      '18:00',
      '19:00',
      '20:00',
      '21:00',
      '22:00',
      '23:00 ',
      '00:00',
    ]
    return (
      <div
        style={{
          flex: 1,
          flexDirection: 'column',
          overflowY: 'auto',
          WebkitOverflowScrolling: 'touch',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          height: '800px',
        }}
      >
        <div className={styles.card}>
          <div className={styles.showPath}>
            <Picture color={'#AAAAAA'} width={20} height={20} />
            <p className={styles.patchTitle}>{t('stepFinish.publishIn')}</p>
          </div>

          <div className={styles.iconsField}>
            <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
            >
              <Pinterest color={'#AAAAAA'} width={24} height={24} />
            </div>

            <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
              onClick={() => handleClick(1)}
            >
              <Linkedin
                color={selectedValues[1] ? '#6A0098' : '#AAAAAA'}
                width={24}
                height={24}
              />
            </div>

            <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
              onClick={() => handleClick(4)}
            >
              <Instagram
                color={selectedValues[4] ? '#6A0098' : '#AAAAAA'}
                width={24}
                height={24}
              />
            </div>

            <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
              onClick={() => handleClick(3)}
            >
              <TikTok
                color={selectedValues[3] ? '#6A0098' : '#AAAAAA'}
                width={24}
                height={24}
              />
            </div>

            <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
              onClick={() => handleClick(2)}
            >
              <XTwitter
                color={selectedValues[2] ? '#6A0098' : '#AAAAAA'}
                width={24}
                height={24}
              />
            </div>

            {/* <div
              style={{
                width: 24,
                marginLeft: -2,
              }}
              className={styles.clickable}
            >
              <Pinterest color={'#AAAAAA'} width={24} height={24} />
            </div> */}
          </div>
        </div>

        <div
          style={{
            marginTop: 30,
          }}
          className={styles.card}
        >
          <div className={styles.showPath}>
            <Calendar2 color={'#AAAAAA'} width={20} height={20} />
            <p className={styles.patchTitle}>{t('stepFinish.dateAndHour')}</p>
          </div>

          <div className={styles.box_calendar}>
            <div
              style={{
                flex: 1,
              }}
            >
              <SchedulerCalendar
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            </div>

            <div className={styles.container_hour_carousel}>
              <p className={styles.container_hour_carousel_title}>
                {t('stepFinish.hour')}
              </p>
              <CarouselHours
                items={hours}
                setValueMinute={setValueMinute}
                valueMinute={valueMinute}
                setValueHour={setValueHour}
                valueHour={valueHour}
              />
            </div>
          </div>
        </div>

        {tiktokCreatorInfo !== false && selectedValues[3] && imageUrl && (
          <div
            style={{
              marginTop: 30,
            }}
            className={styles.card}
          >
            <div className={styles.showPath}>
              <Subtract color={'#6A0098'} width={20} height={20} />
              <TikTok
                color={selectedValues[3] ? '#6A0098' : '#AAAAAA'}
                width={24}
                height={24}
              />
              <p className={styles.patchTitle}>
                {`Configurações do TikTok - ${tiktokCreatorInfo.creator_nickname}`}
              </p>

              <img
                style={{
                  maxWidth: '40px',
                  borderRadius: '200px',
                }}
                src={`${tiktokCreatorInfo.creator_avatar_url}`}
              />
            </div>

            <div
              style={{
                marginTop: 30,
                backgroundColor: '#fff8c4',
                display: 'flex',
                alignItems: 'center',
                gap: 20,
                borderColor: '#ffd700',
              }}
              className={styles.warningCardTiktok}
            >
              <div>
                <TikTokIcon width={12} height={12} />
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                }}
              >
                <div>
                  <div className={styles.selectedModalTextTitle}>
                    {t('stepMyPublication.warning')}
                  </div>
                  <div className={styles.selectedModalTextSubtitle}>
                    {t('stepMyPublication.warning_description')}
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: 30,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <div>
                <LockClosed width={12} height={12} />
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                }}
              >
                <div className={styles.selectedModalTextTitle}>
                  {'Privacidade da publicação'}
                  <SelectSquare
                    htmlFor="select"
                    placeholder="Selecione a privacidade da sua publicação"
                    padding="10px 10px 10px 15px"
                    // value={tiktokPrivacyLevel}
                    tiktokContentDisclosure={tiktokContentDisclosure}
                    options={dropDownTiktok}
                    onClickItem={({ value }) => {
                      handlePrivacyLevel(value)
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: 18,
                backgroundColor: tiktokComment
                  ? '#f5eaf9'
                  : tiktokCreatorInfo.comment_disabled
                  ? '#edeae8'
                  : '#FBFBFB', //'#6A0098',
                display: 'flex',
                alignItems: 'center',
                gap: 20,
                pointerEvents: tiktokCreatorInfo.comment_disabled
                  ? 'none'
                  : 'auto',
                opacity: '0.5 : 1',
              }}
              className={styles.selectedModalCard}
              onClick={() =>
                tiktokComment ? setTiktokComment(false) : setTiktokComment(true)
              }
            >
              <div
                style={{
                  width: 34,
                  height: 34,
                  border: '1px solid #dcdcdc',
                  borderRadius: 200,
                  backgroundColor: tiktokComment
                    ? '#6A0098'
                    : tiktokCreatorInfo.comment_disabled
                    ? '#edeae8'
                    : '#FBFBFB', //'#6A0098',
                }}
              ></div>
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('stepMyPublication.comment')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('stepMyPublication.comment_description')}
                </div>
              </div>
            </div>
            {imageUrl && (
              <div
                style={{
                  marginTop: 30,
                  backgroundColor: '#fff8c4',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                  borderColor: '#ffd700',
                }}
                className={styles.selectedModalCard}
              >
                <div>
                  <TikTokIcon width={12} height={12} />
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <div>
                    <div className={styles.selectedModalTextTitle}>
                      {t('stepMyPublication.warning')}
                    </div>
                    <div className={styles.selectedModalTextSubtitle}>
                      {t('stepMyPublication.music_confirmation_link')}
                      <a
                        target="_blank"
                        href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
                        rel="noreferrer"
                      >
                        {' '}
                        {t(
                          'stepMyPublication.tiktokBrandedInfoContentPromotionalLink2'
                        )}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {imageUrl && (
              <div
                style={{
                  marginTop: 18,
                  backgroundColor: tiktokMusic ? '#f5eaf9' : '#FBFBFB', //'#6A0098',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                }}
                className={styles.selectedModalCard}
                onClick={() =>
                  tiktokMusic ? setTiktokMusic(false) : setTiktokMusic(true)
                }
              >
                <div
                  style={{
                    width: 34,
                    height: 34,
                    border: '1px solid #dcdcdc',
                    borderRadius: 200,
                    backgroundColor: tiktokMusic ? '#6A0098' : '#FBFBFB', //'#6A0098',
                  }}
                ></div>

                <div>
                  <div className={styles.selectedModalTextTitle}>Música</div>
                  <div className={styles.selectedModalTextSubtitle}>
                    {t('stepMyPublication.music_description')}
                  </div>
                  {/*{tiktokMusic !== false && (
                    <div className={styles.warningBox}>
                      {t('stepMyPublication.music_confirmation')}
                      <a
                        target="_blank"
                        href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
                        rel="noreferrer"
                      >
                        {' '}
                        {t('stepMyPublication.music_confirmation_link')}
                      </a>
                      .
                    </div>
                  )}*/}
                </div>
              </div>
            )}
            {/* {videoUrl && (
                  <div
                    style={{
                      marginTop: 18,
                      backgroundColor: tiktokDuet
                        ? '#f5eaf9'
                        : tiktokCreatorInfo.duet_disabled
                        ? '#edeae8'
                        : '#FBFBFB', //'#6A0098',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 20,
                      pointerEvents: tiktokCreatorInfo.duet_disabled
                        ? 'none'
                        : 'auto',
                      opacity: '0.5 : 1',
                    }}
                    className={styles.selectedModalCard}
                    onClick={() =>
                      tiktokDuet ? setTiktokDuet(false) : setTiktokDuet(true)
                    }
                  >
                    <div
                      style={{
                        width: 34,
                        height: 34,
                        border: '1px solid #dcdcdc',
                        borderRadius: 200,
                        backgroundColor: tiktokDuet
                          ? '#6A0098'
                          : tiktokCreatorInfo.duet_disabled
                          ? '#edeae8'
                          : '#FBFBFB', //'#6A0098',
                      }}
                    ></div>
                    <div>
                      <div className={styles.selectedModalTextTitle}>
                        {t('stepMyPublication.duet')}
                      </div>
                      <div className={styles.selectedModalTextSubtitle}>
                        {t('stepMyPublication.duet_description')}
                      </div>
                    </div>
                  </div>
                )}
                {videoUrl && (
                  <div
                    style={{
                      marginTop: 18,
                      backgroundColor: tiktokStitch
                        ? '#f5eaf9'
                        : tiktokCreatorInfo.stitch_disabled
                        ? '#edeae8'
                        : '#FBFBFB', //'#6A0098',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 20,
                      pointerEvents: tiktokCreatorInfo.stitch_disabled
                        ? 'none'
                        : 'auto',
                      opacity: '0.5 : 1',
                    }}
                    className={styles.selectedModalCard}
                    onClick={() =>
                      tiktokStitch
                        ? setTiktokStitch(false)
                        : setTiktokStitch(true)
                    }
                  >
                    <div
                      style={{
                        width: 34,
                        height: 34,
                        border: '1px solid #dcdcdc',
                        borderRadius: 200,
                        backgroundColor: tiktokStitch
                          ? '#6A0098'
                          : tiktokCreatorInfo.stitch_disabled
                          ? '#edeae8'
                          : '#FBFBFB', //'#6A0098',
                      }}
                    ></div>
                    <div>
                      <div className={styles.selectedModalTextTitle}>
                        Stitch
                      </div>
                      <div className={styles.selectedModalTextSubtitle}>
                        {t('stepMyPublication.stitch_description')}
                      </div>
                    </div>
                  </div>
                )} */}
            <div
              style={{
                marginTop: 18,
                backgroundColor: tiktokContentDisclosure
                  ? '#f5eaf9'
                  : '#FBFBFB', //'#6A0098',
                display: 'flex',
                alignItems: 'center',
                gap: 20,
                // pointerEvents: tiktokCreatorInfo.stitch_disabled
                //   ? 'none'
                //   : 'auto',
                opacity: '0.5 : 1',
              }}
              className={styles.selectedModalCard}
              onClick={
                () => handleTiktokContentDisclosure()
                // tiktokContentDisclosure
                //   ? setTiktokContentDisclosure(false)
                //   : setTiktokContentDisclosure(true),
              }
            >
              <div
                style={{
                  width: 34,
                  height: 34,
                  border: '1px solid #dcdcdc',
                  borderRadius: 200,
                  backgroundColor: tiktokContentDisclosure
                    ? '#6A0098'
                    : '#FBFBFB', //'#6A0098',
                }}
              ></div>
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('stepMyPublication.contentDisclosure')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('stepMyPublication.contentDisclosure_description')}
                </div>
              </div>
            </div>
            {tiktokContentDisclosure && (
              <div
                style={{
                  marginTop: 30,
                  backgroundColor: '#fff8c4',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                  borderColor: '#ffd700',
                }}
                className={styles.selectedModalCard}
              >
                <div>
                  <TikTokIcon width={12} height={12} />
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <div>
                    <div className={styles.selectedModalTextTitle}>
                      {t('stepMyPublication.warning')}
                    </div>
                    <div className={styles.selectedModalTextSubtitle}>
                      {tiktokBrandedInfo}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                marginTop: 18,
                backgroundColor: tiktokYourBrand
                  ? '#f5eaf9'
                  : !tiktokContentDisclosure
                  ? '#edeae8'
                  : '#FBFBFB', //'#6A0098',
                display: 'flex',
                alignItems: 'center',
                gap: 20,
                pointerEvents: !tiktokContentDisclosure ? 'none' : 'auto',
                opacity: '0.5 : 1',
              }}
              className={styles.selectedModalCard}
              onClick={() => handleYourBrand()}
            >
              <div
                style={{
                  width: 34,
                  height: 34,
                  border: '1px solid #dcdcdc',
                  borderRadius: 200,
                  backgroundColor: tiktokYourBrand
                    ? '#6A0098'
                    : !tiktokContentDisclosure
                    ? '#edeae8'
                    : '#FBFBFB', //'#6A0098',
                }}
              ></div>
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('stepMyPublication.yourBrand')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('stepMyPublication.yourBrand_description')}
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: 18,
                backgroundColor: tiktokBrandedContent
                  ? '#f5eaf9'
                  : !tiktokContentDisclosure
                  ? '#edeae8'
                  : '#FBFBFB', //'#6A0098',
                display: 'flex',
                alignItems: 'center',
                gap: 20,
                pointerEvents: !tiktokContentDisclosure ? 'none' : 'auto',
                opacity: '0.5 : 1',
              }}
              className={styles.selectedModalCard}
              onClick={() => handleYourBrandingContent()}
            >
              <div
                style={{
                  width: 34,
                  height: 34,
                  border: '1px solid #dcdcdc',
                  borderRadius: 200,
                  backgroundColor: tiktokBrandedContent
                    ? '#6A0098'
                    : !tiktokContentDisclosure
                    ? '#edeae8'
                    : '#FBFBFB', //'#6A0098',
                }}
              ></div>
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('stepMyPublication.brandedContent')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('stepMyPublication.brandedContent_description')}
                </div>
              </div>
            </div>
          </div>
        )}
        {selectedValues[2] && imageUrl && (
          <div
            style={{
              marginTop: 30,
            }}
            className={styles.card}
          >
            <div className={styles.showPath}>
              <Subtract color={'#6A0098'} width={20} height={20} />
              <XTwitter color={'#6A0098'} width={24} height={24} />
              <p className={styles.patchTitle}>{'Configurações do X'}</p>
            </div>

            <div
              style={{
                marginTop: 30,
                backgroundColor: '#fff8c4',
                display: 'flex',
                alignItems: 'center',
                gap: 20,
                borderColor: '#ffd700',
              }}
              className={styles.warningCardTiktok}
            >
              <div>
                <XTwitter color={'#6A0098'} width={24} height={24} />
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                }}
              >
                <div>
                  <div className={styles.selectedModalTextTitle}>
                    {'Aviso sobre o limite de caracteres:'}
                  </div>
                  <div className={styles.selectedModalTextSubtitle}>
                    {
                      '* Postagens de contas padrão no X estão limitadas a 280 caracteres.'
                    }
                  </div>
                  <div className={styles.selectedModalTextSubtitle}>
                    {
                      '* Para contas com assinatura do X Blue, o limite é de até 10.000 caracteres.'
                    }
                  </div>
                  <div className={styles.selectedModalTextSubtitle}>
                    {
                      'Certifique-se de que sua postagem está dentro do limite permitido antes de enviá-la.'
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {selectedValues[1] && imageUrl && (
          <div
            style={{
              marginTop: 30,
            }}
            className={styles.card}
          >
            <div className={styles.showPath}>
              <Subtract color={'#6A0098'} width={20} height={20} />
              <Linkedin color={'#6A0098'} width={24} height={24} />
              <p className={styles.patchTitle}>{'Configurações do Linkedin'}</p>
            </div>

            <div
              style={{
                marginTop: 30,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <div>
                <LockClosed width={12} height={12} />
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                }}
              >
                <div className={styles.selectedModalTextSubtitle}>
                  <SelectSquare
                    htmlFor="select"
                    placeholder={t('stepMyPublication.selectPrivacyLevel')}
                    padding="10px 10px 10px 15px"
                    // value={tiktokPrivacyLevel}
                    // tiktokContentDisclosure={tiktokContentDisclosure}
                    options={[
                      {
                        id: 1,
                        label: 'Público',
                        value: 'PUBLIC',
                      },
                      {
                        id: 2,
                        label: 'Conexões',
                        value: 'CONNECTIONS',
                      },
                    ]}
                    onClickItem={({ value }) => {
                      handlePrivacyLevel(value)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {selectedValues[4] && imageUrl && (
          <div
            style={{
              marginTop: 30,
            }}
            className={styles.card}
          >
            <div className={styles.showPath}>
              <Subtract color={'#6A0098'} width={20} height={20} />
              <Instagram color={'#6A0098'} width={24} height={24} />
              <p className={styles.patchTitle}>
                {'Configurações do Instagram'}
              </p>
            </div>
            {imageUrl && (
              <div
                style={{
                  marginTop: 30,

                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                }}
                className={styles.selectedModalCard}
              >
                <div>
                  <Picture color={'#6A0098'} width={20} height={20} />
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <div className={styles.selectedModalTextSubtitle}>
                    {'Selecione o tipo de publicação'}
                    <MultiplySelectSquare
                      backgroundColor={'#EDEDED'}
                      border={true}
                      borderColor={'#dadada'}
                      placeholder={'Selecione'}
                      arrowDirection="down"
                      variante="gray"
                      options={[
                        {
                          id: 2,
                          label: 'Feed',
                          value: 'FEED',
                        },
                        {
                          id: 3,
                          label: 'Stories',
                          value: 'STORIES',
                        },
                      ]}
                      fontSize={12}
                      // onClickItem={handleSelectedIntem}
                      onClickItem={(value) => {
                        handleSelectedIntem('instagramItems', value)
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {/* {videoUrl && (
                <div
                  style={{
                    marginTop: 30,

                    display: 'flex',
                    alignItems: 'center',
                    gap: 20,
                  }}
                  className={styles.selectedModalCard}
                >
                  <div>
                    <Picture color={'#6A0098'} width={20} height={20} />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    <div className={styles.selectedModalTextSubtitle}>
                      <MultiplySelectSquare
                        htmlFor="select"
                        // backgroundColor={'#EDEDED'}
                        // border={true}
                        // borderColor={'#dadada'}
                        placeholder={'Selecione o tipo de publicação'}
                        arrowDirection="down"
                        variante="gray"
                        options={
                          selectedValues[1]
                            ? [
                                {
                                  id: 1,
                                  label: 'Feed',
                                  value: 'FEED',
                                },
                              ]
                            : [
                                {
                                  id: 1,
                                  label: 'Feed',
                                  value: 'FEED',
                                },
                                {
                                  id: 2,
                                  label: 'Stories',
                                  value: 'STORIES',
                                },
                              ]
                        }
                        fontSize={12}
                        onClickItem={(value) => {
                          handlePublicationModeMovie('instagramItems', value)
                        }}
                        // onClickItem={handlePublicationModeMovie}
                        defaultSelected={[
                          {
                            id: 1,
                            label: 'Feed',
                            value: 'FEED',
                          },
                          {
                            id: 2,
                            label: 'Stories',
                            value: 'STORIES',
                          },
                        ].find((item) => item?.id === publicationMovieType)}
                      />
                    </div>
                  </div>
                </div>
              )} */}

            <div
              onClick={() => setOppenedModalPersons(true)}
              style={{
                marginTop: 18,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <MorePersonsIcon color={'#610f92'} size={'24'} />
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('stepMyPublication.markPeople')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('stepMyPublication.markPeople_description')}
                </div>
              </div>
            </div>

            <div
              onClick={() => setOppennedModalHashtags(true)}
              style={{
                marginTop: 18,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <HashtagIcon color={'#610f92'} size={'22'} />
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('stepMyPublication.addHashtags')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('stepMyPublication.addHashtags_description')}
                </div>
              </div>
            </div>
          </div>
        )}

        {selectedValues[5] && imageUrl && (
          <div
            style={{
              marginTop: 30,
            }}
            className={styles.card}
          >
            <div className={styles.showPath}>
              <Subtract color={'#6A0098'} width={20} height={20} />
              <Facebook color={'#6A0098'} width={24} height={24} />
              <p className={styles.patchTitle}>{'Configurações do Facebook'}</p>
            </div>

            {imageUrl && (
              <div
                style={{
                  marginTop: 30,

                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                }}
                className={styles.selectedModalCard}
              >
                <div>
                  <Picture color={'#6A0098'} width={20} height={20} />
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <div className={styles.selectedModalTextSubtitle}>
                    <SelectSquare
                      backgroundColor={'#EDEDED'}
                      border={true}
                      borderColor={'#dadada'}
                      placeholder={'Selecione'}
                      arrowDirection="down"
                      options={[
                        {
                          id: 3,
                          label: 'Previa Feed Do Facebook',
                        },
                        {
                          id: 4,
                          label: 'Previa Stories Do Facebook',
                        },
                      ]}
                      fontSize={12}
                      onClickItem={handleSelectedIntem}
                    />
                  </div>
                </div>
              </div>
            )}
            {/* {videoUrl && (
                <div
                  style={{
                    marginTop: 30,

                    display: 'flex',
                    alignItems: 'center',
                    gap: 20,
                  }}
                  className={styles.selectedModalCard}
                >
                  <div>
                    <Picture color={'#6A0098'} width={20} height={20} />
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    <div className={styles.selectedModalTextSubtitle}>
                      <SelectSquare
                        backgroundColor={'#EDEDED'}
                        border={true}
                        borderColor={'#dadada'}
                        placeholder={'Tipo de publicação'}
                        arrowDirection="down"
                        options={
                          selectedValues[1]
                            ? [
                                {
                                  id: 1,
                                  label: 'Feed',
                                  value: 'FEED',
                                },
                              ]
                            : [
                                {
                                  id: 1,
                                  label: 'Feed',
                                  value: 'FEED',
                                },
                                {
                                  id: 2,
                                  label: 'Stories',
                                  value: 'STORIES',
                                },
                              ]
                        }
                        fontSize={12}
                        onClickItem={handlePublicationModeMovie}
                        defaultSelected={[
                          {
                            id: 1,
                            label: 'Feed',
                            value: 'FEED',
                          },
                          {
                            id: 2,
                            label: 'Stories',
                            value: 'STORIES',
                          },
                        ].find((item) => item?.id === publicationMovieType)}
                      />
                    </div>
                  </div>
                </div>
              )} */}

            <div
              onClick={() => setOppenedModalLocale(true)}
              style={{
                marginTop: 18,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <PinMapsIcon color={'#610f92'} size={'24'} />
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('stepMyPublication.location')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('stepMyPublication.location_description')}
                </div>
              </div>
            </div>

            <div
              onClick={() => setOppenedModalPersons(true)}
              style={{
                marginTop: 18,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <MorePersonsIcon color={'#610f92'} size={'24'} />
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('stepMyPublication.markPeople')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('stepMyPublication.markPeople_description')}
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: 18,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <FacebookChatIcon color={'#610f92'} size={'24'} />

              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('stepMyPublication.receiveMessages')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('stepMyPublication.receiveMessages_description')}
                </div>
              </div>
            </div>

            <div
              onClick={() => setOppennedModalLinks(true)}
              style={{
                marginTop: 18,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <LinksIcon color={'#610f92'} size={'22'} />
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('stepMyPublication.addLinks')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('stepMyPublication.addLinks_description')}
                </div>
              </div>
            </div>

            <div
              onClick={() => setOppennedModalHashtags(true)}
              style={{
                marginTop: 18,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <HashtagIcon color={'#610f92'} size={'22'} />
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('stepMyPublication.addHashtags')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('stepMyPublication.addHashtags_description')}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* {tiktokCreatorInfo !== false && selectedValues[3] && (
          <div
            style={{
              marginTop: 30,
            }}
            className={styles.card}
          >
            <div className={styles.showPath}>
              <Subtract color={'#AAAAAA'} width={20} height={20} />
              <p
                className={styles.patchTitle}
              >{`Configurações do TikTok - ${tiktokCreatorInfo.creator_nickname}`}</p>
              <img
                style={{
                  maxWidth: '40px',
                  borderRadius: '200px',
                }}
                src={`${tiktokCreatorInfo.creator_avatar_url}`}
              />
            </div>

            <div
              style={{
                marginTop: 30,
                backgroundColor: '#fff8c4',
                display: 'flex',
                alignItems: 'center',
                gap: 20,
                borderColor: '#ffd700',
              }}
              className={styles.warningCardTiktok}
            >
              <div>
                <TikTokIcon width={12} height={12} />
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                }}
              >
                <div>
                  <div className={styles.selectedModalTextTitle}>
                    {t('stepFinish.warning')}
                  </div>
                  <div className={styles.selectedModalTextSubtitle}>
                    {t('stepFinish.warningDescription')}
                    
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: 30,

                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
            >
              <div>
                <LockClosed width={12} height={12} />
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                }}
              >
                <div className={styles.selectedModalTextSubtitle}>
                  <SelectSquare
                    htmlFor="select"
                    placeholder="Selecione a privacidade da sua publicação"
                    tiktokContentDisclosure={tiktokContentDisclosure}
                    padding="10px 10px 10px 15px"
                    value={dropDownTiktok?.value}
                    options={dropDownTiktok}
                    onClickItem={({ value }) => {
                      handlePrivacyLevel(value)
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: 18,
                backgroundColor: tiktokComment
                  ? '#f5eaf9'
                  : tiktokCreatorInfo.comment_disabled
                  ? '#edeae8'
                  : '#FBFBFB', //'#6A0098',
                display: 'flex',
                alignItems: 'center',
                gap: 20,
                pointerEvents: tiktokCreatorInfo.comment_disabled
                  ? 'none'
                  : 'auto',
                opacity: '0.5 : 1',
              }}
              className={styles.selectedModalCard}
              onClick={() =>
                tiktokComment ? setTiktokComment(false) : setTiktokComment(true)
              }
            >
              <div
                style={{
                  width: 34,
                  height: 34,
                  border: '1px solid #dcdcdc',
                  borderRadius: 200,
                  backgroundColor: tiktokComment
                    ? '#6A0098'
                    : tiktokCreatorInfo.comment_disabled
                    ? '#edeae8'
                    : '#FBFBFB', //'#6A0098',
                }}
              ></div>
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('stepFinish.comment')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('stepFinish.commentDescription')}
                </div>
              </div>
            </div>
            {imageUrl && (
              <div
                style={{
                  marginTop: 30,
                  backgroundColor: '#fff8c4',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                  borderColor: '#ffd700',
                }}
                className={styles.selectedModalCard}
              >
                <div>
                  <TikTokIcon width={12} height={12} />
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <div>
                    <div className={styles.selectedModalTextTitle}>Aviso</div>
                    <div className={styles.selectedModalTextSubtitle}>
                      {t('stepFinish.musicConfirmationLink')}
                      <a
                        target="_blank"
                        href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
                        rel="noreferrer"
                      >
                        {' '}
                        {t('stepFinish.musicConfirmation')}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
           
            <div
              style={{
                marginTop: 18,
                backgroundColor: tiktokMusic ? '#f5eaf9' : '#FBFBFB', //'#6A0098',
                display: 'flex',
                alignItems: 'center',
                gap: 20,
              }}
              className={styles.selectedModalCard}
              onClick={() =>
                tiktokMusic ? setTiktokMusic(false) : setTiktokMusic(true)
              }
            >
              <div
                style={{
                  width: 34,
                  height: 34,
                  border: '1px solid #dcdcdc',
                  borderRadius: 200,
                  backgroundColor: tiktokMusic ? '#6A0098' : '#FBFBFB', //'#6A0098',
                }}
              ></div>
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('stepFinish.music')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('stepFinish.musicDescription')}
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: 18,
                backgroundColor: tiktokContentDisclosure
                  ? '#f5eaf9'
                  : '#FBFBFB', 
                display: 'flex',
                alignItems: 'center',
                gap: 20,
                opacity: '0.5 : 1',
              }}
              className={styles.selectedModalCard}
              onClick={
                () => handleTiktokContentDisclosure()
              }
            >
              <div
                style={{
                  width: 34,
                  height: 34,
                  border: '1px solid #dcdcdc',
                  borderRadius: 200,
                  backgroundColor: tiktokContentDisclosure
                    ? '#6A0098'
                    : '#FBFBFB', 
                }}
              ></div>
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('stepFinish.contentDisclosure')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('stepFinish.contentDisclosureDescription')}
                </div>
              </div>
            </div>

            {tiktokContentDisclosure && (
              <div
                style={{
                  marginTop: 30,
                  backgroundColor: '#fff8c4',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 20,
                  borderColor: '#ffd700',
                }}
                className={styles.selectedModalCard}
              >
                <div>
                  <TikTokIcon width={12} height={12} />
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <div>
                    <div className={styles.selectedModalTextTitle}>
                      {t('stepFinish.warning')}
                    </div>
                    <div className={styles.selectedModalTextSubtitle}>
                      {tiktokBrandedInfo}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div
              style={{
                marginTop: 18,
                backgroundColor: tiktokYourBrand
                  ? '#f5eaf9'
                  : !tiktokContentDisclosure
                  ? '#edeae8'
                  : '#FBFBFB', //'#6A0098',
                display: 'flex',
                alignItems: 'center',
                gap: 20,
                pointerEvents: !tiktokContentDisclosure ? 'none' : 'auto',
                opacity: '0.5 : 1',
              }}
              className={styles.selectedModalCard}
              onClick={() => handleYourBrand()}
            >
              <div
                style={{
                  width: 34,
                  height: 34,
                  border: '1px solid #dcdcdc',
                  borderRadius: 200,
                  backgroundColor: tiktokYourBrand
                    ? '#6A0098'
                    : !tiktokContentDisclosure
                    ? '#edeae8'
                    : '#FBFBFB', //'#6A0098',
                }}
              ></div>
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('stepFinish.yourBrand')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('stepFinish.yourBrandDescription')}
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: 18,
                backgroundColor: tiktokBrandedContent
                  ? '#f5eaf9'
                  : !tiktokContentDisclosure
                  ? '#edeae8'
                  : '#FBFBFB', //'#6A0098',
                display: 'flex',
                alignItems: 'center',
                gap: 20,
                pointerEvents: !tiktokContentDisclosure ? 'none' : 'auto',
                opacity: '0.5 : 1',
              }}
              className={styles.selectedModalCard}
              onClick={() => handleYourBrandingContent()}
            >
              <div
                style={{
                  width: 34,
                  height: 34,
                  border: '1px solid #dcdcdc',
                  borderRadius: 200,
                  backgroundColor: tiktokBrandedContent
                    ? '#6A0098'
                    : !tiktokContentDisclosure
                    ? '#edeae8'
                    : '#FBFBFB', //'#6A0098',
                }}
              ></div>
              <div>
                <div className={styles.selectedModalTextTitle}>
                  {t('stepFinish.brandedContent')}
                </div>
                <div className={styles.selectedModalTextSubtitle}>
                  {t('stepFinish.brandedContentDescription')}
                </div>
              </div>
            </div>
          </div>
        )} */}

        {/* <div
          style={{
            marginTop: 30,
          }}
          className={styles.card}
        >
          <div className={styles.showPath}>
            <Subtract color={'#AAAAAA'} width={20} height={20} />
            <p className={styles.patchTitle}>{'Configurações Adicionais'}</p>
          </div>
          <div
            onClick={() => setOppenedModalLocale(true)}
            style={{
              marginTop: 30,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <PinMapsIcon color={'#610f92'} size={'24'} />
            <div>
              <div className={styles.selectedModalTextTitle}>
                {t('stepFinish.location')}
              </div>
              <div className={styles.selectedModalTextSubtitle}>
                {t('stepFinish.locationDescription')}
              </div>
            </div>
          </div>

          <div
            onClick={() => setOppenedModalPersons(true)}
            style={{
              marginTop: 18,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <MorePersonsIcon color={'#610f92'} size={'24'} />
            <div>
              <div className={styles.selectedModalTextTitle}>
                {t('stepFinish.markPeople')}
              </div>
              <div className={styles.selectedModalTextSubtitle}>
                {t('stepFinish.markPeopleDescription')}
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: 18,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <FacebookChatIcon color={'#610f92'} size={'24'} />

            <div>
              <div className={styles.selectedModalTextTitle}>
                {t('stepFinish.receiveMessages')}
              </div>
              <div className={styles.selectedModalTextSubtitle}>
                {t('stepFinish.receiveMessagesDescription')}
              </div>
            </div>
          </div>

          <div
            onClick={() => setOppennedModalLinks(true)}
            style={{
              marginTop: 18,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <LinksIcon color={'#610f92'} size={'22'} />
            <div>
              <div className={styles.selectedModalTextTitle}>
                {t('stepFinish.addLinks')}
              </div>
              <div className={styles.selectedModalTextSubtitle}>
                {t('stepFinish.addLinksDescription')}
              </div>
            </div>
          </div>

          <div
            onClick={() => setOppennedModalHashtags(true)}
            style={{
              marginTop: 18,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <HashtagIcon color={'#610f92'} size={'22'} />
            <div>
              <div className={styles.selectedModalTextTitle}>
                {t('stepFinish.addHashtags')}
              </div>
              <div className={styles.selectedModalTextSubtitle}>
                {t('stepFinish.addHashtagsDescription')}
              </div>
            </div>
          </div>
        </div> */}

        {/* <div
          style={{
            marginTop: 30,
          }}
          className={styles.card}
        >
          <div className={styles.showPath}>
            <Subtract color={'#AAAAAA'} width={20} height={20} />
            <p className={styles.patchTitle}>
              {t('stepFinish.privacySettings')}
            </p>
          </div>

          <div
            style={{
              marginTop: 30,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <div
              style={{
                width: 34,
                height: 34,
                border: '1px solid #dcdcdc',
                borderRadius: 200,
              }}
            ></div>
            <div>
              <div className={styles.selectedModalTextTitle}>
                {t('stepFinish.public')}
              </div>
              <div className={styles.selectedModalTextSubtitle}>
                {t('stepFinish.publicDescription')}
              </div>
            </div>
          </div>

          <div
            style={{
              marginTop: 18,

              display: 'flex',
              alignItems: 'center',
              gap: 20,
            }}
            className={styles.selectedModalCard}
          >
            <div
              style={{
                width: 34,
                height: 34,
                border: '1px solid #dcdcdc',
                borderRadius: 200,
              }}
            ></div>
            <div>
              <div className={styles.selectedModalTextTitle}>
                {t('stepFinish.restricted')}
              </div>
              <div className={styles.selectedModalTextSubtitle}>
                {t('stepFinish.restrictedDescription')}
              </div>
            </div>
          </div>
        </div> */}
      </div>
    )
  }

  const SchedulePhotosField = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',

          width: 480,
          marginLeft: 20,
          gap: 20,
        }}
      >
        <div
          style={{
            backgroundColor: '#fbfbfb',
            flex: 1,
          }}
        >
          <div className={styles.container_select_preview}>
            {/* <SelectSquare
              backgroundColor={'#EDEDED'}
              border={true}
              borderColor={'#dadada'}
              placeholder={t('stepFinish.selectPreview')}
              arrowDirection="down"
              options={[
                {
                  id: 1,
                  label: t('stepFinish.previewFeedFacebook'),
                },
                {
                  id: 2,
                  label: t('stepFinish.previewFeedInstagram'),
                },
                {
                  id: 3,
                  label: t('stepFinish.previewFeedFacebookStories'),
                },
                {
                  id: 4,
                  label: t('stepFinish.previewFeedInstagramStories'),
                },
              ]}
              fontSize={12}
              //  defaultSelected={locationsStatesOptions.find(
              //    (e) => e.value === locationsStatesOptions?.value
              //  )}
              onClickItem={handleSelectedIntem}
            /> */}
          </div>
          {/* <ImageRender image={imageUrl} type={valueItim} legend={legendId} /> */}
          <ImageRender
            image={imageUrl}
            type={valueItem}
            legend={legendId}
            setValueItem={setValueItem}
            selectedValues={selectedValues}
          />
        </div>
        <div>{/* <CarrouselComponent /> */}</div>
      </div>
    )
  }

  const ButtonsBottomField = () => {
    return (
      <div
        style={{
          height: 70,
        }}
      >
        <div className={styles.button_nav}>
          <button
            className={styles.button_back}
            onClick={() => {
              setStep('stepSchedule')
            }}
          >
            <p className={styles.button_title}>{t('stepFinish.backButton')}</p>
          </button>

          <div
            style={{
              display: 'flex',
              gap: '10px',
            }}
          >
            <button
              className={styles.button_next}
              onClick={() => {
                handleSelectDate()
              }}
            >
              <p className={styles.button_title}>
                {t('stepFinish.finishButton')}
              </p>
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className={styles.container_page}>
        <div
          style={{
            display: 'flex',
            flex: 1,
          }}
        >
          {ScheduleInfoField()}
          {SchedulePhotosField()}
        </div>

        <ButtonsBottomField />

        <ModalComponent
          isOpen={oppenedModalLocale}
          onClose={() => setOppenedModalLocale(false)}
          style={{
            width: 600,
            height: 240,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>
                {t('stepFinish.location')}
              </div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppenedModalLocale(false)
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <input
              type="text"
              placeholder="Digite aqui..."
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppenedModalLocale(false)
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  Finalizar
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>

        <ModalComponent
          isOpen={oppenedModalPersons}
          onClose={() => setOppenedModalPersons(false)}
          style={{
            width: 600,
            height: 280,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>
                {t('stepFinish.markPeople')}
              </div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppenedModalPersons(false)
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <div className={styles.iconsField} style={{ marginTop: 20 }}>
              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Pinterest color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Linkedin color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <Instagram color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <TikTok color={'#AAAAAA'} width={24} height={24} />
              </div>

              <div
                style={{
                  width: 24,
                  marginLeft: -2,
                }}
                className={styles.clickable}
              >
                <XTwitter color={'#AAAAAA'} width={24} height={24} />
              </div>
            </div>

            <input
              type="text"
              placeholder={t('stepFinish.markPeoplePlaceholder')}
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppenedModalPersons(false)
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  {t('stepFinish.finishButton')}
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>

        <ModalComponent
          isOpen={oppennedModalLinks}
          onClose={() => setOppennedModalLinks(false)}
          style={{
            width: 600,
            height: 240,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>
                {t('stepFinish.addLink')}
              </div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppennedModalLinks(false)
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <input
              type="text"
              placeholder={t('stepFinish.addLinkPlaceholder')}
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppennedModalLinks(false)
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  Finalizar
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>

        <ModalComponent
          isOpen={oppennedModalHashtags}
          onClose={() => setOppennedModalHashtags(false)}
          style={{
            width: 600,
            height: 240,
          }}
        >
          <div className={styles.modal_content}>
            <div className={styles.header_close}>
              <div className={styles.modal_title}>
                {t('stepFinish.addHashtags')}
              </div>
              <div
                className={styles.close_modal}
                onClick={() => {
                  setOppennedModalHashtags(false)
                }}
              >
                <Cross width={12} height={12} />
              </div>
            </div>

            <input
              type="text"
              placeholder={t('stepFinish.addHashtagsPlaceholder')}
              style={{
                width: '100%',
                height: 40,
                borderRadius: 5,
                border: '1px solid #ededed',
                backgroundColor: '#ededed',
                padding: '0 10px',
                marginTop: 20,
                marginBottom: 20,

                color: '#190027',
              }}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '10px',
              }}
            >
              <button
                className={styles.button_next}
                style={{
                  height: 40,
                  width: 180,
                }}
                onClick={() => {
                  setOppennedModalHashtags(false)
                }}
              >
                <p
                  className={styles.button_title}
                  style={{
                    fontSize: 12,
                  }}
                >
                  {t('stepFinish.finishButton')}
                </p>
              </button>
            </div>
          </div>
        </ModalComponent>
      </div>
    </>
  )
}

export default StepFinish
